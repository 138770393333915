/* eslint @typescript-eslint/no-explicit-any: "off" */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { setArrayPrototypeReduce } from "./js-polyfills";
/**
 * Load ruffle from an automatically-detected location.
 *
 * This function returns a new instance of Ruffle and downloads it every time.
 * You should not use it directly; this module will memoize the resource
 * download.
 *
 * @returns A ruffle constructor that may be used to create new Ruffle
 * instances.
 */
function fetchRuffle() {
    return __awaiter(this, void 0, void 0, function* () {
        if (typeof Array.prototype.reduce !== "function" ||
            Array.prototype.reduce.toString().indexOf("[native code]") === -1) {
            // Some external libraries override the `Array.prototype.reduce` method in a way
            // that causes Webpack to crash (#1507, #1865), so we need to override it again.
            setArrayPrototypeReduce();
        }
        try {
            // If ruffleRuntimePath is defined then we are executing inside the extension
            // closure. In that case, we configure our local Webpack instance.
            __webpack_public_path__ = ruffleRuntimePath + "dist/";
        }
        catch (e) {
            // Checking an undefined closure variable usually throws ReferenceError,
            // so we need to catch it here and continue onward.
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
        // We currently assume that if we are not executing inside the extension,
        // then we can use webpack to get Ruffle.
        const module = yield import("../pkg/ruffle_web");
        return module.Ruffle;
    });
}
let lastLoaded = null;
/**
 * Obtain an instance of `Ruffle`.
 *
 * This function returns a promise which yields `Ruffle` asynchronously.
 *
 * @returns A ruffle constructor that may be used to create new Ruffle
 * instances.
 */
export function loadRuffle() {
    if (lastLoaded == null) {
        lastLoaded = fetchRuffle();
    }
    return lastLoaded;
}
